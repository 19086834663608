* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body,
html {
  font-family: "Cairo", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: rgb(252, 252, 252);
  background-color: rgb(52, 52, 52);
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}
h2,
h3,
h4 {
  font-family: "Cairo", sans-serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: rgb(252, 252, 252);
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: rgb(252, 252, 252);
}
h4 {
  font-size: 18px;
  color: rgb(252, 252, 252);
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
h1, h2, h3 {
  margin-top: 0px;
}
p {
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}
.text-myPrimary {
  color: #ffc52b;
}
/* Navigation */
#menu {
  padding: 5px 10px 10px;
  transition: all 0.8s;
  width: 100%;
}
#menu.navbar-default {
  background-color: rgb(52, 52, 52);
  border-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  z-index: 99;
}

#menu div.navbar-brand {
  font-family: "Cairo", sans-serif;
  width: 100%;
}
#menu .navigation-img {
  width: 180px;
  margin-top: 0;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Cairo", sans-serif;
  text-transform: uppercase;
  color: rgb(252, 252, 252);
  font-size: 18px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px;
}
.navbar-toggler {
  background-color: #fff ;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}
.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Cairo", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
/* About Section */
#about {
  padding: 100px 0;
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
/* #about h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
} */
#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #ffc52b;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  max-width: 520px;
  width: 100%;
  margin-top: 10px;
  border-right: 0;
}
#about p {
  line-height: 24px;
  margin: 15px 0;
}
/* Contact Section */

/* Footer Section*/
#footer {
  background: #fff;
  padding: 20px 0;
}
#footer p {
  color: #232222;
  font-size: 14px;
}
#footer a {
  color: #608dfd;
}
#footer a:hover {
  border-bottom: 2px solid #608dfd;
}
/* contactUs Section */
#contact-us {
  padding: 50px 0;
}
#contact-us h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#contact-us h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact-us img {
  width: 520px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#contact-us .contacts ul li a {
  text-decoration: none;
  color: #ffc52b;
}
#contact-us .contacts ul li span {
  text-decoration: none;
  color: rgb(252, 252, 252);
}
#contact-us p {
  line-height: 24px;
  margin: 15px 0;
}
#contact-us .map-section {
  width: 100%;
  margin: 0 auto;
  padding-top: 150px;
  height: 100%;
}
@media (max-width : 991px) {
  #contact-us .map-section {
    padding-top: 0;
    height: 300px;
  }
}
.float-whatsapp-icon {
  position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	left:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	/* box-shadow: 2px 2px 3px #999; */
  z-index:100;
}
.whatsapp-icon {
  margin-top: 16px;
}